import * as React from 'react';
import { Link } from 'gatsby';
import LayoutContainer from '../containers/layout';
import ButtonGoldBlackComponent from '../components/button-gold-black';

const NotFoundPage = () => (
  <LayoutContainer title="Not found" description="Page you are looking for was not found">
    <div className="container mx-auto p-6">
      <div className="text-4xl lg:text-5xl text-black mb-12">Sorry</div>
      <div className="mb-12">We couldn’t find what you were looking for.</div>
      <div className="w-80">
        <Link to="/">
          <ButtonGoldBlackComponent title="Go" subtitle="to the homepage" />
        </Link>
      </div>
    </div>
  </LayoutContainer>
);

export default NotFoundPage;
